import React, {useState, useEffect} from 'react'
import {useRouteMatch, useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {addToast} from "reducers/System"
import cx from 'classnames'

import API from "library/API"
import {handleFieldChange} from "library/FormHandlers"

import NavBar from "includes/NavBar"

import fs from 'styles/forms.module.sass'
import Editor from "components/system/Editor"


function SaveJobPage(props) {

	const history = useHistory()
	const [catData, setCatData] = useState([])
	const ln = props.ln
	const match = useRouteMatch()
	const _id = match.params._id

	const [fields, setFields] = useState({
		category: null,
		title: { en: '', zh: '' },
		location: { en: '', zh: '' },
		salary: '',
		qty: 1,
		description: { en: '', zh: '' },
		requirements: { en: '', zh: '' }
	})

	useEffect(() => {

		let api = new API()
		api.getJobCategories()
			.then(res => {
				console.log(res)
				setCatData(res.categories)
			})

		if (_id) {
			api.getJob(_id)
				.then(res =>{
					setFields(res.job)
				})
		}
	}, [])

	function handleChange(e) {
		handleFieldChange(e, fields, setFields)
	}

	async function handleSubmit(e) {
		e.preventDefault()

		let api = new API()
		let res = await api.saveJob({...fields, _id})
		console.log(res)
		// if (res.result === 'success') {
			props.addToast('职位保存成功')
			if (!_id) history.push('/jobs')
		// }
	}

	let typeOptions = catData.map(cat => (
		<div className={cx(fs.option, { [fs.selected]: fields.category === cat._id })}
			key={cat._id}
	 		onClick={e => handleChange({...e, target: { value: cat._id, name: 'category' }}) }
		>
			{cat.name[ln]}
		</div>
	))

	return (
		<main>

			<NavBar title={"编辑职位"}
				back={"/jobs"}
			/>

			<section>

				<form className={fs.standard} onSubmit={handleSubmit}>

					<div className={fs.clickSelector}>
						{typeOptions}
					</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>标题 (中文)</label>
							<input type={"text"} name={"title.zh"} value={fields.title.zh} onChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>标题 (English)</label>
							<input type={"text"} name={"title.en"} value={fields.title.en} onChange={handleChange} />
						</div>
					</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>地点 (中文)</label>
							<input type={"text"} name={"location.zh"} value={fields.location.zh} onChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>地点 (English)</label>
							<input type={"text"} name={"location.en"} value={fields.location.en} onChange={handleChange} />
						</div>
					</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>工资（可选）</label>
							<legend>请输入工资范围。默认值：面议</legend>
							<input
								type={"text"}
						   		name={"salary"}
						   		value={fields.salary}
								placeholder={"面议"}
								onChange={handleChange}
								style={{ display: "inline-block", width: '250px' }}
							/>
						</div>
						<div className={fs.field}>
							<label>数量（可选）</label>
							<legend>请输入空缺职位数量。默认值：若干</legend>
							<input type={"text"}
						   		name={"qty"}
						   		value={fields.qty}
						   		onChange={handleChange}
								style={{ width: '100px' }}
							/>
						</div>
					</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>描述 (中文)</label>
							<Editor name={'description.zh'} data={fields.description.zh} handleChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>描述 (English)</label>
							<Editor name={'description.en'} data={fields.description.en} handleChange={handleChange} />
						</div>
					</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>要求 (中文)</label>
							<Editor name={'requirements.zh'} data={fields.requirements.zh} handleChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>要求 (English)</label>
							<Editor name={'requirements.en'} data={fields.requirements.en} handleChange={handleChange} />
						</div>
					</div>

					<input type={"submit"} value={"保存职位"} />

				</form>

			</section>

		</main>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState, {addToast})(SaveJobPage)
