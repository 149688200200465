import React, {useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useSelector} from "react-redux"

function AccessHandler(props) {

	const history = props.history
	const path = history.location.pathname

	const {email, permissions} = useSelector(state => state.session)

	useEffect(() => {
		// console.log('session email', email, permissions)
		// console.log('session', props.location)
		if (permissions && permissions.indexOf('admin') !== -1) return // don't redirect anything
		if (email) {
			switch (true) {
				case (path.indexOf('/page') === 0):
				case (path.indexOf('/product') === 0):
				case (path.indexOf('/equipment') === 0):
					if (permissions.indexOf('content') === -1) history.push('/')
					break
				case (path.indexOf('/news') === 0):
					if (permissions.indexOf('news') === -1) history.push('/')
					break
				case (path.indexOf('/job') === 0):
					if (permissions.indexOf('jobs') === -1 && permissions.indexOf('applications') === -1) history.push('/')
					break
				case (path.indexOf('/application') === 0):
					if (permissions.indexOf('applications') === -1) history.push('/')
					break
				case (path.indexOf('/user') === 0):
					history.push('/')
					break
			}
		}
	}, [path, email])

	return null

}

export default withRouter(AccessHandler)
