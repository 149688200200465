import {apiHost} from "global"
import {getRefactoredImage} from "./Core"

function Post(props = {}) {

	this._id = props._id
	this.cover = props.cover
	this.coverGrab = props.coverGrab
	this.title = {}
	if (props.title) {
		this.title.en = props.title.en
		this.title.zh = props.title.zh
	}
	this.summary = {}
	if (props.summary) {
		this.summary.en = props.summary.en
		this.summary.zh = props.summary.zh
	}
	this.type = props.type
	this.published = props.published
	this.status = props.status

	this.getCover = (type) => {
		if (!this.cover) return null
		if (this.cover.indexOf('http') > -1) return this.cover
		else return apiHost + '/media/posts/' + getRefactoredImage(this.cover, type)
	}

}

export default Post
