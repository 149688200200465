import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Link} from 'react-router-dom'

import CommonModal from "components/system/CommonModal"

import 'styles/global.sass'
import API from "library/API"
import Application from "./Application"

function JobPanel(props) {

	const dispatch = useDispatch()
	const [appData, setAppData] = useState([])
	const jobCategories = useSelector(state => state.content.jobCategories)
	const permissions = useSelector(state => state.session.permissions)

	useEffect(() => {
		console.log('decide whether to get applications', permissions)
		if (permissions.indexOf('applications') > -1) {
			new API().getApplications({job: props._id})
				.then(res => setAppData(res.applications))
		}
	}, [permissions])

	useEffect(() => {
		console.log('job categories', jobCategories)
	}, [jobCategories])

	let modalProps = {
		heading: '确认删除职位',
		body: '你确认想删除此职位吗?',
		actions: {
			'确认': deleteJob,
			'取消': () => dispatch({ type: 'CLEAR_MODAL' })
		}
	}

	function confirmDelete() {
		dispatch({ type: 'SET_MODAL', modal: <CommonModal {...modalProps} /> })
	}

	function deleteJob() {
		let api = new API()
		api.deleteJob(props._id)
			.then(res => {
				console.log(res)
				props.removeJob(props._id)
				dispatch({ type: 'CLEAR_MODAL' })
			})
	}

	function removeApp(_id) {
		console.log('should be removing')
		setAppData(appData.filter(obj => obj._id !== _id))
	}

	let apps = <div>无权查看此职位申请</div>
	if (appData.length) apps = appData.map(app => <Application {...app} removeApp={removeApp} />)


	return (
		<div className={"panel"} style={{ display: 'block' }}>

			<div style={{ display: 'flex' }}>

				<div className={"column"}>
					<div>{props.title ? props.title.zh : null}</div>
					<div>{props.title ? props.title.en : null}</div>
					<div>类型：{jobCategories.find(obj => obj._id === props.category)?.name?.zh}</div>
				</div>

				<div className={"column"} style={{ flex: 2 }}>
					<div dangerouslySetInnerHTML={{__html: props.description ? props.description.zh : null }} />
					{/*<div>{props.description ? props.description.en : null}</div>*/}
				</div>

				<div className={"actions"}>
					<Link className={"inactive"} to={"#"}>查看</Link>
					<Link to={"/jobs/edit/" + props._id}>编辑</Link>
					<button onClick={confirmDelete}>删除</button>
				</div>

			</div>

			<div style={{ marginTop: '20px' }}>
				<div style={{ padding: '10px 20px', background: '#333', color: 'white' }}>收到的简历</div>
				<div style={{ padding: '20px', background: '#fafafa', border: 'solid 1px #ccc' }}>
					{apps}
				</div>
			</div>

		</div>
	)

}

export default JobPanel
