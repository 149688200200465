import React, {useEffect, useState} from 'react'
import {useRouteMatch, useHistory} from "react-router-dom"
import {connect} from 'react-redux'

import API from "library/API"
import {addToast} from 'reducers/System'
import {handleFieldChange} from "library/FormHandlers"

import NavBar from "includes/NavBar"

import fs from "styles/forms.module.sass"

function SaveEquipmentCategoryPage(props) {

	const history = useHistory()
	const match = useRouteMatch()
	let _id = match.params._id

	const [fields, setFields] = useState({ name: { en: '', zh: '' } })

	useEffect(() => {
		if (_id) {
			let api = new API()
			api.getEquipmentCategory(_id)
				.then(res =>{
					setFields(res.category)
				})
		}
	}, [])

	function handleChange(e) {
		handleFieldChange(e, fields, setFields)
	}

	async function handleSubmit(e) {
		e.preventDefault()

		let api = new API()
		let res = await api.saveEquipmentCategory({...fields, _id})
		console.log(res)
		// if (res.result === 'success') {
		props.addToast('设备类型保存成功')
		if (!_id) history.push('/equipment-categories')
		// }
	}

	return (
		<main>

			<NavBar title={"编辑设备类型"}
				back={"/equipment-categories"}
			/>

			<section>

				<form className={fs.standard} onSubmit={handleSubmit}>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>名称 (中文)</label>
							<input type={"text"} name={"name.zh"} value={fields.name.zh} onChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>名称 (English)</label>
							<input type={"text"} name={"name.en"} value={fields.name.en} onChange={handleChange} />
						</div>
					</div>

					<input type={"submit"} value={"设备类型保存"} />

				</form>

			</section>

		</main>
	)

}

export default connect(null, {addToast})(SaveEquipmentCategoryPage)
