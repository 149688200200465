import {apiHost} from "../global"

class API {

	// Standard functions

	async fetch(path, data) {
		let string = ''
		console.log(data)
		if (data) {
			let x = 0
			for (let key in data) {
				let value = data[key]
				string += (x > 0 ? '&' : '') + key + '=' + value
				x++
			}
		}
		console.log(string)
		let res = await fetch(apiHost + path + '?' + string)
			.then(res => res.json())
			.then(res => {
				return res
			})
		console.log(res)
		return res
	}

	async request(path, data, callback) {

		if (callback) callback(0)

		return new Promise((resolve, reject) => {
			let url = apiHost + path

			let xhr = new XMLHttpRequest()
			xhr.withCredentials = true
			xhr.open('POST', url)
			// xhr.setRequestHeader('Content-type', 'multipart/form-data')

			let formData = this.compileFormData(data)

			if (callback) {
				xhr.addEventListener('progress', (e) => {
					console.log('xhr tracking: progress event', e)
					// callback(e.loaded, e.total)
				})
				xhr.onload = (e) => {
					console.log('xhr tracking: upload done')
				}
				// xhr.upload.addEventListener('progress', (e) => {
				// 	console.log('upload tracking: progress event', e)
				// 	// callback(e.loaded, e.total)
				// })
				// xhr.upload.onload = (e) => {
				// 	console.log('upload tracking: upload done')
				// }
			}

			// request state change event
			xhr.onreadystatechange = function () {

				if (callback) callback(xhr.readyState)

				// request completed?
				if (xhr.readyState !== 4) {
					// console.log('xhr in progress')
					return
				}

				if (xhr.status === 200) {
					// request successful - show response
					// console.log('xhr success', xhr.responseText)
					resolve(JSON.parse(xhr.responseText))
				} else {
					// request error
					console.log('HTTP error', xhr.status, xhr.statusText);
				}
			};

			// start request
			console.log(formData)
			xhr.send(formData)
		})
	}

	compileFormData(data) {
		console.log('compiling the files')
		let formData = new FormData()
		if (!data) return formData

		for (let [key, value] of Object.entries(data)) {
			console.log('compiling the files')
			if (value instanceof FileList) { console.log('multiple file upload')
				for (let x = 0; x < value.length; x++) {
					formData.append(key, value[x])
				}
			} else { // Appends everything else as normal, except of non-file objects (inc. arrays)
				console.log('not multiple files')
				if (typeof value === 'object' && !(value instanceof File)) value = JSON.stringify(value)
				formData.append(key, value)
			}
		}

		return formData
	}


	async login(data) {
		return await this.request('/user/login', data)
	}

	async register(data) {
		return await this.request('/user/register', data)
	}



	async getPages() {
		return await this.request('/page/list')
	}

	async getPage(_id) {
		return await this.request('/page/info', { _id: _id })
	}

	async savePage(data, showProgress) {
		data.apiHost = apiHost
		return await this.request('/page/save', data, showProgress ? this.showProgress : null)
	}

	async deletePageVideo(_id) {
		return await this.request('/page/deleteVideo', { _id: _id })
	}



	async getPosts() {
		return await this.request('/post/list')
	}

	async getPost(_id) {
		return await this.request('/post/info', { _id: _id })
	}

	async savePost(data, showProgress) {
		data.apiHost = apiHost
		return await this.request('/post/save', data, showProgress ? this.showProgress : null)
	}

	async deletePost(_id) {
		console.log('deleting post')
		return await this.request('/post/delete', { _id: _id })
	}

	async updatePost(data) {
		return await this.request('/post/update', data)
	}

	async deletePostVideo(_id) {
		return await this.request('/post/deleteVideo', { _id: _id })
	}




	async getProducts() {
		return await this.request('/product/list')
	}

	async getProduct(_id) {
		return await this.request('/product/info', { _id: _id })
	}

	async saveProduct(data) {
		return await this.request('/product/save', data)
	}

	async deleteProduct(_id) {
		console.log('deleting product')
		return await this.request('/product/delete', { _id: _id })
	}



	async getProductCategories() {
		return await this.request('/product-category/list')
	}

	async getProductCategory(_id) {
		return await this.request('/product-category/info', { _id: _id })
	}

	async saveProductCategory(data) {
		return await this.request('/product-category/save', data)
	}

	async deleteProductCategory(_id) {
		return await this.request('/product-category/delete', { _id: _id })
	}


	async getEquipment() {
		return await this.request('/equipment/list')
	}

	async getEquipmentInfo(_id) {
		return await this.request('/equipment/info', { _id: _id })
	}

	async saveEquipment(data) {
		return await this.request('/equipment/save', data)
	}

	async deleteEquipment(_id) {
		console.log('deleting equipment')
		return await this.request('/equipment/delete', { _id: _id })
	}



	async getEquipmentCategories() {
		return await this.request('/equipment-category/list')
	}

	async getEquipmentCategory(_id) {
		return await this.request('/equipment-category/info', { _id: _id })
	}

	async saveEquipmentCategory(data) {
		return await this.request('/equipment-category/save', data)
	}

	async deleteEquipmentCategory(_id) {
		return await this.request('/equipment-category/delete', { _id: _id })
	}




	async getJobs() {
		return await this.request('/job/list')
	}

	async getJob(_id) {
		return await this.request('/job/info', { _id: _id })
	}

	async saveJob(data) {
		return await this.request('/job/save', data)
	}

	async deleteJob(_id) {
		return await this.request('/job/delete', { _id: _id })
	}


	async getJobCategories() {
		return await this.request('/job-category/list')
	}

	async getJobCategory(_id) {
		return await this.request('/job-category/info', { _id: _id })
	}

	async saveJobCategory(data) {
		return await this.request('/job-category/save', data)
	}

	async deleteJobCategory(_id) {
		return await this.request('/job-category/delete', { _id: _id })
	}



	async getApplications(data) {
		return await this.request('/application/list', data)
	}

	async deleteApplication(_id) {
		return await this.request('/application/delete', { _id: _id })
	}



	async getUsers() {
		return await this.request('/user/list')
	}

	async updateUser(data) {
		return await this.request('/user/update', data)
	}

	async resumeSession() {
		return await this.request('/user/resume')
	}

	async logout() {
		return await this.request('/user/logout')
	}

	async deleteUser(_id) {
		return await this.request('/user/delete', { _id: _id })
	}



	showProgress(state) { // 2, 3, 4
		console.log('show progress', state)
		let overlay = document.querySelector('.progressOverlay')
		overlay.classList.add('active')

		let bar = document.querySelector('.progress')
		let classes = state === 0 ? 'loading' : 'completed'
		bar.className = 'progress ' + classes
		if (state === 4) {

			overlay.classList.remove('active')

			setTimeout(() => {
				bar.classList.add('fade')
			}, 1000)
			setTimeout(() => {
				bar.className = 'progress'
			}, 1500)
		}
	}

}

export default API
