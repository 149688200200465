import React, {useState, useEffect} from 'react'
import {connect} from "react-redux"
import {clearModal} from "reducers/System"
import cx from 'classnames'

import s from './Modal.module.sass'

function Modal(props) {

	const [modal, setModal] = useState()

	useEffect(() => { // Add a delay on clear modal so the shape retains while fading out
		window.setTimeout(setModal, props.modal ? 0 : 1000, props.modal)
	}, [props.modal])

	return (
		<div className={cx(s.wrapper, { [s.active]: props.modal } )}>
			<div className={s.bg} onClick={props.clearModal}/>
			<div className={s.modal}>
				{modal}
			</div>
		</div>
	)

}

const mapState = state => {
	return {
		modal: state.system.modal
	}
}

export default connect(mapState, {clearModal})(Modal)
