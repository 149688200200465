import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux"
import NavBar from "includes/NavBar"
import API from "../../library/API"

import Panel from 'components/products/Panel'

function ProductsPage() {

	const dispatch = useDispatch()
	const productData = useSelector(state => state.content.products)

	useEffect(() => {
		let api = new API()
		api.getProducts()
			.then(res => {
				dispatch({ type: 'SET_PRODUCTS', products: res.products })
			})
	}, [])

	function removeProduct(_id) {
		dispatch({ type: 'SET_PRODUCTS', products: productData.filter(obj => obj._id !== _id) })
	}

	let products = productData.map(product => <Panel key={product._id} {...product} removeProduct={removeProduct} />)

	return (
		<main>

			<NavBar title={"产品管理"}
				back={"/"}
				actions={{ text: "+ 创建产品", link: '/product/create' }}
			/>

			{products}

		</main>
	)

}

export default ProductsPage
