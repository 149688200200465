import React, {useState, useEffect} from 'react'
import {useHistory} from "react-router-dom"
import {useSelector} from 'react-redux'

import NavBar from "includes/NavBar"
import API from "library/API"

import Panel from "components/users/Panel"

function UsersPage() {

	const history = useHistory()
	const permissions = useSelector(state => state.session.permissions)
	if (permissions.indexOf('admin') === -1) history.push('/')

	const [userData, setUserData] = useState([])

	useEffect(() => {
		let api = new API()
		api.getUsers()
			.then(res => {
				console.log(res)
				setUserData(res.users)
			})
	}, [])

	function removeUser(_id) {
		let filtered = userData.filter(obj => obj._id !== _id)
		setUserData(filtered)
	}

	let users = userData.map(user => {
		return (
			<Panel {...user} removeUser={removeUser} />
		)
	})

	return (
		<main>

			<NavBar
				title={"用户管理"}
				/>

			<div className={"help"}>
				管理员：所有授权（包括用户授权）<br />
				网站内容：页面，产品，设备添加编辑<br />
				新闻：新闻添加编辑<br />
				职位：职位添加编辑，工作申请查看下载<br />
				申请通知：收到招聘申请收邮件通知
			</div>

			<div>
				{users}
			</div>

		</main>
	)

}

export default UsersPage
