import React, {Component} from "react"
import {CKEditor} from "@ckeditor/ckeditor5-react"
import FullEditor from "ckeditor5-build-full"
import CKAdapterPlugin from "library/CKAdapter"

import "./Editor.css"

class Editor extends Component {

    constructor(props) {
        super(props)
        this.config = {
            heading: {
                options: [
                    { model: 'paragraph', title: '段落 Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading2', view: 'h2', title: '标题 Title', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: '小标题 Subtitle', class: 'ck-heading_heading3' }
                ]
            },
            toolbar: {
                items: [
                    'heading', '|',
                    // 'fontfamily', '|',
                    'alignment', '|',
                    'fontColor', 'fontSize',
                    // 'fontBackgroundColor', '|',
                    'bold', 'italic', 'strikethrough', 'underline',
                    // 'subscript', 'superscript', '|',
                    'link', '|',
                    // 'outdent', 'indent', '|',
                    'bulletedList', 'numberedList',
                    // 'todoList', '|',
                    // 'code', 'codeBlock', '|',
                    'insertTable', '|',
                    'uploadImage', 'insertMedia', 'blockQuote', '|',
                    'undo', 'redo'
                ],
                shouldNotGroupWhenFull: true
            },
            // toolbarGroups: [{ name: 'colors' }],
        }
    }

    render() {

        return (
            <CKEditor
                editor={FullEditor}
                config={this.config}
                data={this.props.data}
                onChange={(e, editor) => {
                    let test = {
                        target: {
                            name: this.props.name,
                            value: editor.getData()
                        }
                    }
                    this.props.handleChange(test)
                    // editor.getData();
                    // this.props.name
                }}
                onReady={editor => CKAdapterPlugin(editor)}
                />
        )

    }

}

export default Editor
