import React, {useState, useEffect} from 'react'
import {useRouteMatch, useHistory} from 'react-router-dom'
import {connect} from 'react-redux'

import {apiHost} from "../../global"
import {addToast} from "reducers/System"
import Editor from "components/system/Editor"
import API from "library/API"
import NavBar from "includes/NavBar"

import fs from 'styles/forms.module.sass'

const types = {
	company: {
		zh: '公司新闻',
		en: 'Company News'
	},
	industry: {
		zh: '行业动态',
		en: 'Industry News'
	}
}

function SaveNewsPage(props) {

	const history = useHistory()
	const ln = props.ln
	const match = useRouteMatch()
	let _id = match.params._id

	const [fields, setFields] = useState({
		title: { en: '', zh: '' },
		summary: { en: '', zh: '' },
		body: { en: '', zh: '' },
		type: 'company',
		video: null
	})

	useEffect(() => {
		if (_id) {
			let api = new API()
			api.getPost(_id)
				.then(res =>{
					setFields(res.post)
				})
		}
	}, [])

	const deleteButtonStyle = {
		position: 'absolute',
		zIndex: 100,
		top: '10px',
		right: '10px',
		width: '30px',
		height: '30px',
		background: 'black',
		borderRadius: '50%',
		color: 'white',
		textAlign: 'center',
		lineHeight: '30px'
	}

	function deleteVideo() {
		let api = new API()
		api.deletePostVideo(_id)
			.then(res => {
				console.log(res)
				if (res.result === 'success') {
					setFields({...fields, video: null})
				}
			})
	}

	function handleChange(e) {
		console.log(e)
		let {name, value} = e.target
		let nameArr = name.split('.')
		console.log(name, value)

		setFields({
			...fields,
			[nameArr[0]]: nameArr.length === 1 ? value : { ...fields[nameArr[0]], [nameArr[1]]: value }
		})
	}

	function handleFile(e) {
		console.log('file size is', e.target.files[0].size)
		if (e.target.files[0].size > 1048576 * 100) {
			alert('文件大小太大')
			e.target.value = ''
		} else {
			setFields({ ...fields, video: e.target.files })
		}
	}

	async function handleSubmit(e) {
		e.preventDefault()
		let api = new API()
		let data = {...fields}
		if (_id) data._id = _id
		let res = await api.savePost(data, !!(fields.video || fields.cover))
		console.log(res)
		props.addToast('新闻保存成功')
		if (!_id) history.push('/news')
	}

	let typeOptions = Object.entries(types).map(entry => {
		let [key, value] = entry
		console.log('the current type is', fields.type)
		return (
			<span key={key}>
				<input type={"radio"} name={"type"} value={key} checked={fields.type === key}
					onChange={handleChange} />
				<label>{value.zh}</label>
			</span>
		)
	})

	return (
		<main>

			<NavBar title={"编辑新闻"}
				back={"/news"}
			/>

			<section>

				<form className={fs.standard} onSubmit={handleSubmit}>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>新闻类型</label>
							<div className={fs.radioSet}>
								{typeOptions}
							</div>
						</div>

					<div className={fs.field}>
						<label>发布时间</label>
						<input type={"date"} name={"published"} value={fields.published} onChange={handleChange} />
					</div>
						</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>标题 (中文)</label>
							<input type={"text"} name={"title.zh"} value={fields.title.zh} onChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>标题 (English)</label>
							<input type={"text"} name={"title.en"} value={fields.title.en} onChange={handleChange} />
						</div>
					</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>概括 (中文)</label>
							<textarea name={"summary.zh"} value={fields.summary.zh} onChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>概括 (English)</label>
							<textarea name={"summary.en"} value={fields.summary.en} onChange={handleChange} />
						</div>
					</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>内容 (中文)</label>
							{/*<Editor data={'test'} handleChange={handleChange} />*/}
							<Editor name={'body.zh'} data={fields.body.zh || ''} handleChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>内容 (English)</label>
							<Editor name={'body.en'} data={fields.body.en || ''} handleChange={handleChange} />
						</div>
					</div>

					<br />
					<br />


					<label>封面照片（可选）</label>

					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '30px', width: '300px', height: '225px', background: '#eee' }}>
							{fields.cover && <img src={apiHost + '/media/posts/' + fields.cover} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />}
						</div>
						<div style={{  }}>
							<input type={"file"} name={"image"} multiple={true} onChange={handleFile} />
						</div>
					</div>

					<label style={{ marginTop: '30px' }}>视频（可选）</label>

					<legend style={{ marginBottom: '20px' }}>支持MP4格式。建议分辨率1080p到720p。最大文件大小100MB</legend>

					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '30px', width: '300px', minHeight: '150px', background: '#eee' }}>
							{
								fields.video &&
								<div style={{ position: 'relative' }}>
									<span onClick={deleteVideo} style={deleteButtonStyle}>X</span>
									<video controls width={300}>
										<source src={apiHost + '/media/posts/' + fields.video} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
									</video>
								</div>
							}
						</div>
						<div style={{  }}>
							<input type={"file"} name={"video"} multiple={true} accept={"video/mp4"} onChange={handleFile} />
						</div>
					</div>


					<input type={"submit"} value={ln === 'en' ? "Save News" : '保存新闻'} />


				</form>

			</section>

		</main>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState, {addToast})(SaveNewsPage)
