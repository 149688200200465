import React, {useState} from 'react'
import cx from 'classnames'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {setModal, clearModal} from "reducers/System"

import API from "library/API"
import Post from "library/Post"

import CommonModal from "components/system/CommonModal"

import 'styles/global.sass'

function Panel(props) {

	const [post, setPost] = useState(new Post(props))
	console.log(post)

	let modalProps = {
		heading: '确认删除',
		body: '你确认想删除吗?',
		actions: {
			'确认': deletePost,
			'取消': props.clearModal
		}
	}

	function toggleStatus() {
		let api = new API()
		let targetStatus = post.status === 1 ? 0 : 1
		console.log('post status', post.status)
		api.updatePost({ _id: post._id, status: targetStatus })
			.then(res => {
				console.log('post status', post.status)
				setPost(new Post({...post, status: targetStatus }))
			})
	}

	function confirmDelete() {
		props.setModal(<CommonModal {...modalProps} />)
	}

	function deletePost() {
		let api = new API()
		api.deletePost(props._id)
			.then(res => {
				console.log(res)
				props.removePost(props._id)
				props.clearModal()
			})
	}

	return (
		<div className={"panel"}>

			<div className={"cover"} style={{ position: 'relative' }}>
				<img src={post.getCover('thumb')} />
				<div style={{ position: 'absolute', zIndex: 10, bottom: 0, left: 0, padding: '10px', background: 'black', color: 'white', fontSize: '10px' }}>
					{props.coverGrab ? 'auto' : 'uploaded'}
				</div>
			</div>

			<div className={"column"}>
				<div>类型: {post.type}</div>
				<div>{post.title && post.title.en}</div>
				<div>{post.title && post.title.zh}</div>
			</div>

			<div className={"column"}>
				<div>发布时间: {post.published}</div>
				<div>{post.summary && post.summary.en}</div>
				<div>{post.summary && post.summary.zh}</div>
			</div>

			<div className={"actions"}>
				<button className={cx({ active: post.status })} onClick={toggleStatus}>
					启动
				</button>
				<Link>查看</Link>
				<Link to={"/news/edit/" + post._id}>编辑</Link>
				<button onClick={confirmDelete}>删除</button>
			</div>

		</div>
	)

}

export default connect(null, {setModal, clearModal})(Panel)
