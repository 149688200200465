import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {setModal, clearModal} from "reducers/System"

import API from "library/API"
import Equipment from "library/Equipment"

import CommonModal from "components/system/CommonModal"

import 'styles/global.sass'

function Panel(props) {

	let equipment = new Equipment(props)
	console.log(equipment)

	let modalProps = {
		heading: '确认删除',
		body: 'Are you sure you want to delete this equipment?',
		actions: {
			'Accept': deleteEquipment,
			'Cancel': props.clearModal
		}
	}

	function confirmDelete() {
		props.setModal(<CommonModal {...modalProps} />)
	}

	function deleteEquipment() {
		let api = new API()
		api.deleteEquipment(props._id)
			.then(res => {
				console.log(res)
				props.removeEquipment(props._id)
				props.clearModal()
			})
	}

	return (
		<div className={"panel"}>

			<div className={"cover"}>
				<img src={equipment.getImage('thumb')} />
			</div>

			<div className={"column"}>
				<div>类型：{equipment.category}</div>
				<div>{equipment.name.en}</div>
				<div>{equipment.name.zh}</div>
			</div>

			<div className={"column"}>
				<div>{equipment.description.en}</div>
				<div>{equipment.description.zh}</div>
			</div>

			<div className={"actions"}>
				<Link to={"#"}>查看</Link>
				<Link to={"/equipment/edit/" + equipment._id}>编辑</Link>
				<button onClick={confirmDelete}>删除</button>
			</div>

		</div>
	)

}

export default connect(null, {setModal, clearModal})(Panel)
