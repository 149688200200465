import React from 'react'

import './ProgressBar.sass'

function ProgressBar() {

	return (
		<div className={"progressOverlay"}>
			<div className={"progressShade"} />
			<div className={"progressWindow"}>
				<div className={"progressText"}>处理中...</div>
				<div className={"progressBar"}>
					<div className={"progress"} />
				</div>
			</div>
		</div>
	)

}

export default ProgressBar
