import React from "react"
import {BrowserRouter} from 'react-router-dom'

import Header from "./includes/Header"
import ToastHandler from "./components/system/ToastHandler"
import Modal from "./components/system/Modal"
import Pages from "./Pages"
import AccessHandler from "./includes/AccessHandler"

function App() {

	return (
		<BrowserRouter>

			<AccessHandler />
			<ToastHandler />
			<Modal />

			<Header />

			<Pages />

		</BrowserRouter>
	)
}

export default App
