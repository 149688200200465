import React from 'react'
import {useDispatch} from 'react-redux'
import {Link} from "react-router-dom"

import API from "../../library/API"
import CommonModal from "components/system/CommonModal"

import s from './CategoryPanel.module.sass'

function CategoryPanel(props) {

	const dispatch = useDispatch()
	let modalProps = {
		heading: '确认删除',
		body: '你确认想删除此职位类型吗',
		actions: {
			'确认': deleteCategory,
			'取消': () => dispatch({ type: 'CLEAR_MODAL' })
		}
	}

	function confirmDelete() {
		dispatch({ type: 'SET_MODAL', modal: <CommonModal {...modalProps} /> })
	}

	function deleteCategory() {
		let api = new API()
		api.deleteJobCategory(props._id)
			.then(res => {
				props.removeCat(props._id)
				dispatch({ type: 'CLEAR_MODAL' })
			})
	}

	return (
		<div className={s.panel}>
			<span>{props.name.zh}</span>
			<span>{props.name.en}</span>
			<div className={s.spacer} />
			<div className={s.actions}>
				<Link to={"/job-category/edit/" + props._id}>编辑</Link>
				<button onClick={confirmDelete}>删除</button>
			</div>
		</div>
	)

}

export default CategoryPanel
