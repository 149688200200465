import React from 'react'
import {Link, useHistory} from 'react-router-dom'

import s from './NavBar.module.sass'

function NavBar(props) {

	const history = useHistory()

	let actions
	if (Array.isArray(props.actions)) {
		actions = props.actions.map(action => compileAction(action))
	} else if (props.actions) {
		actions = compileAction(props.actions)
	}

	return (
		<div className={s.bar}>
			<h1>{props.title}</h1>
			<a className={s.back} onClick={() => history.goBack()}>
				◁ 返回
			</a>
			<div className={s.actions}>
				{actions}
			</div>
		</div>
	)
}

function compileAction(action) {
	return <Link to={action.link}>{action.text}</Link>
}

export default NavBar
