import React from 'react'
import {useHistory, Link, NavLink} from 'react-router-dom'
import {useSelector, useDispatch} from "react-redux"

import {setUser} from "../reducers/Session"

import s from './Header.module.sass'
import API from "../library/API"
import ProgressBar from "../components/system/ProgressBar"

const links = [
	{
		href: '/pages',
		label: { zh: '页面', en: 'Pages' },
		requirement: 'content'
	},
	{
		href: '/news',
		label: { zh: '新闻', en: 'News' },
		requirement: 'news'
	},
	{
		href: '/products',
		label: { zh: '产品', en: 'Products' },
		requirement: 'content'
	},
	{
		href: '/product-categories',
		label: { zh: '产品类型', en: 'Product Categories' },
		requirement: 'content'
	},
	{
		href: '/equipment',
		label: { zh: '设备', en: 'Equipment' },
		requirement: 'content'
	},
	{
		href: '/equipment-categories',
		label: { zh: '设备类型', en: 'Equipment Categories' },
		requirement: 'content'
	},
	{
		href: '/jobs',
		label: { zh: '职位', en: 'Jobs' },
		requirement: ['jobs', 'applications']
	},
	{
		href: '/job-categories',
		label: { zh: '职位类型', en: 'Job Categories' },
		requirement: 'content'
	},
	{
		href: '/users',
		label: { zh: '用户', en: 'Users' },
		requirement: 'admin'
	}
]

function Header() {

	const history = useHistory()
	const dispatch = useDispatch()
	const {email, first, last, permissions} = useSelector(state => ({...state.session}))

	function logout() {
		let api = new API()
		api.logout()
			.then(res => {
				if (res.result === 'success') {
					dispatch(setUser({ email: '', permissions: [] }))
					history.push('/login')
				}
			})
	}

	let navLinks = links.map(link => {
		let className = s.locked
		if (permissions) {
			if (permissions.indexOf('admin') > -1) {
				className = null
			}
			if (email) {
				if (typeof link.requirement === 'string') link.requirement = [link.requirement]
				for (let req of link.requirement) {
					if (permissions.indexOf(req) > -1) {
						className = null
						break
					}
				}
			}
		}
		return (
			<NavLink to={link.href} className={className} key={link.href}>{link.label.zh}</NavLink>
		)
	})

	return (
		<header className={s.header}>

			<Link className={s.logo} to={"/"}><strong>科森CMS</strong></Link>
			<nav>
				{navLinks}
			</nav>

			<div className={s.account}>
				{email}
			</div>

			<button onClick={logout}>
				退出
			</button>

			<ProgressBar />

		</header>
	)

}

export default Header
