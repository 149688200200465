import React, {useState, useEffect, useRef} from 'react'
import {useRouteMatch} from 'react-router-dom'
import {connect} from 'react-redux'

import {handleFieldChange} from "library/FormHandlers"
import {addToast} from "reducers/System"
import Editor from "components/system/Editor"
import API from "library/API"
import NavBar from "includes/NavBar"

import fs from 'styles/forms.module.sass'
import {apiHost} from "../../global";

function SavePage(props) {

	const ref = useRef()
	const vidRef = useRef()
	const ln = props.ln
	const match = useRouteMatch()
	const _id = match.params._id

	const [fields, setFields] = useState({
		slug: '',
		title: { en: '', zh: '' },
		body: { en: '', zh: '' },
		video: null
	})

	const deleteButtonStyle = {
		position: 'absolute',
		zIndex: 100,
		top: '10px',
		right: '10px',
		width: '30px',
		height: '30px',
		background: 'black',
		borderRadius: '50%',
		color: 'white',
		textAlign: 'center',
		lineHeight: '30px'
	}

	useEffect(() => {
		if (_id) {
			let api = new API()
			api.getPage(_id)
				.then(res =>{
					setFields(res.page)
				})
		}
	}, [])

	function handleChange(e) {
		handleFieldChange(e, fields, setFields)
	}

	function handleFile(e) {
		console.log('file size is', e.target.files[0].size)
		if (e.target.files[0].size > 1048576 * 100) {
			alert('文件大小太大')
			e.target.value = ''
		} else {
			setFields({ ...fields, video: e.target.files })
		}
	}

	function handleProgress(loaded, total) {
		console.log('handling progress', loaded, total)
	}

	// function testSubmit(e) {
	// 	e.preventDefault()
	// 	let xhr = new XMLHttpRequest()
	// 	xhr.responseType = 'json'
	// 	xhr.withCredentials = true
	// 	let formData = new FormData()
	// 	formData.append('body', JSON.stringify({ en: '', zh: '' }))
	// 	formData.append('video', vidRef.current.files[0])
	//
	// 	xhr.open('POST', 'http://localhost:4000/page/save')
	// 	xhr.upload.addEventListener('progress', (e) => {
	// 		console.log('progress', e.loaded, e.total)
	// 	})
	// 	// xhr.setRequestHeader('Content-Type', 'multipart/form-data')
	// 	xhr.send(formData)
	// }

	async function handleSubmit(e) {
		e.preventDefault()
		let api = new API()
		let data = {...fields}
		if (_id) data._id = _id
		let res = await api.savePage(data, true)
		console.log(res)
		props.addToast('页面保存成功')
	}

	async function deleteVideo() {
		let api = new API()
		api.deletePageVideo(_id)
			.then(res => {
				console.log(res)
				if (res.result === 'success') {
					setFields({...fields, video: null})
				}
			})
	}

	return (
		<main>

			<NavBar title={"编辑页面"}
				back={"/pages"}
			/>

			<section>

				<form className={fs.standard} onSubmit={handleSubmit} ref={ref}>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>Slug</label>
							<input type={"text"} name={"slug"} value={fields.slug} onChange={handleChange} />
						</div>
					</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>标题 (中文)</label>
							<input type={"text"} name={"title.zh"} value={fields.title.zh} onChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>标题 (English)</label>
							<input type={"text"} name={"title.en"} value={fields.title.en} onChange={handleChange} />
						</div>
					</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>内容 (中文)</label>
							<Editor name={'body.zh'} data={fields.body.zh || ''} handleChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>内容 (English)</label>
							<Editor name={'body.en'} data={fields.body.en || ''} handleChange={handleChange} />
						</div>
					</div>

					<br />
					<br />

					<label>顶部视频</label>

					<legend style={{ marginBottom: '20px' }}>支持MP4格式。建议分辨率1080p到720p。最大文件大小100MB</legend>

					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '30px', width: '300px', minHeight: '150px', background: '#eee' }}>
							{
								fields.video &&
								<div style={{ position: 'relative' }}>
									<span onClick={deleteVideo} style={deleteButtonStyle}>X</span>
									<video controls width={300}>
										<source src={apiHost + '/media/pages/' + fields.video} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
									</video>
								</div>
							}
						</div>
						<div style={{  }}>
							<input type={"file"} name={"video"} multiple={true} ref={vidRef}
						   		accept={"video/mp4"}
						   		onChange={handleFile} />
						</div>
					</div>

					<input type={"submit"} value={ln === 'en' ? 'Save Page' : '保存页面'} />

				</form>

			</section>

		</main>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState, {addToast})(SavePage)
