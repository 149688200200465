import {apiHost} from "global"
import {getRefactoredImage} from "./Core"

function Equipment(props = {}) {

	this._id = props._id
	this.category = props.category
	this.name = {}
	if (props.name) {
		this.name.en = props.name.en
		this.name.zh = props.name.zh
	}
	this.description = {}
	if (props.description) {
		this.description.en = props.description.en
		this.description.zh = props.description.zh
	}
	this.image = props.image

	this.getImage = (type) => {
		if (!this.image) return null
		return apiHost + '/media/equipment/' + getRefactoredImage(this.image, type)
	}

}

export default Equipment
