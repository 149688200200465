import React, {useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

import permissionsData from "library/UserPermissions"
import {setModal, clearModal} from "reducers/System"
import {addToast} from "reducers/System"
import API from "library/API"

import CommonModal from "components/system/CommonModal"

import 'styles/global.sass'

function Panel(props) {

	const ref = useRef()
	const ln = props.ln
	const [permissions, setPermissions] = useState(props.permissions)
	const [notifications, setNotifications] = useState(props.notifications)
	console.log(props)

	let modalProps = {
		heading: '确认删除',
		body: '你确认想删掉用户吗？',
		actions: {
			'确认': deleteUser,
			'取消': props.clearModal
		}
	}

	function deleteUser(_id) {
		let api = new API()
		api.deleteUser(props._id)
			.then(res => {
				console.log(res)
				props.removeUser(props._id)
				props.clearModal()
			})
	}

	function confirmDelete() {
		props.setModal(<CommonModal {...modalProps} />)
	}

	let permissionOptions = permissionsData.map((permission, x) => {
		return (
			<>
				<div>
					<input type={"checkbox"} name={"permissions"} onClick={handlePermissionsCheck} value={permission.name}
						defaultChecked={permissions && permissions.indexOf(permission.name) > -1}
					/>
					<label>{permission.label.zh}</label>
				</div>
				{x === 0 && <hr />}
			</>
		)
	})

	function handlePermissionsCheck(e) {
		let name = e.target.value
		let value = e.target.checked

		let perm = []
		if (!value) perm = permissions.filter(obj => obj !== name)
		else perm = [...permissions, name]
		setPermissions(perm)

		let api = new API()
		let data = {
			_id: props._id,
			permissions: perm
		}
		api.updateUser(data)
			.then(res => {
				console.log(res)
				props.addToast('用户授权保存成功了')
			})
	}

	function handleNotificationsCheck() {
		let api = new API()
		let targetNotifications = null
		if (!notifications) targetNotifications = ['applications']
		let data = {
			_id: props._id,
			notifications: targetNotifications,
		}
		api.updateUser(data)
			.then(res => {
				console.log(res)
				setNotifications(targetNotifications)
				props.addToast('邮件通知设定保存成功了')
			})
	}

	return (
		<div className={"panel"}>

			<div className={"column"} style={{ flex: 'none', width: '300px' }}>
				<div>电子邮箱：{props.email}</div>
				<div>姓：{props.last}</div>
				<div>名：{props.first}</div>
			</div>

			<div className={"column"} ref={ref}>
				{permissionOptions}
				{/*{permissions.toString()}*/}
				<hr />
				<div>
					<input type={"checkbox"} onClick={handleNotificationsCheck}
			   			defaultChecked={notifications && notifications.indexOf('applications') > -1} />
					<label>{ln === 'zh' ? '招聘通知' : 'Application Notifications'}</label>
				</div>
			</div>

			<div className={"actions"}>
				{/*<Link to={"/user/edit/" + props._id}>编辑</Link>*/}
				<button onClick={confirmDelete}>删除</button>
			</div>

		</div>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState, {addToast, setModal, clearModal})(Panel)
