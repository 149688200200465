import React  from 'react'
import cx from 'classnames'

import s from './Toast.module.sass'
// import {CSSTransition} from "react-transition-group"

function Toast(props) {

	console.log('toast props', props)
	// useEffect(() => {
	// 	let element = document.querySelector('.' + s.toast)
	// 	console.log(element.clientHeight)
	// }, [])

	return (
		<div className={cx(s.toast, { [s.error]: props.type === 'error' })}>
			{props.msg}
		</div>
	)

}

export default Toast
