import React from 'react'
import cx from 'classnames'

import s from './CommonModal.module.sass'

function CommonModal(props) {

	return (
		<div className={cx(s.modal, { [s.withActions]: props.actions })}>
			{props.heading && <div className={s.heading}>{props.heading}</div>}
			<div className={s.body}>{props.body}</div>
			<Actions {...props.actions} />
		</div>
	)

}

function Actions(props) {

	if (!props) return null
	let buttons = Object.entries(props).map(([label, fn]) => <button onClick={fn}>{label}</button>)

	return (
		<div className={s.actions}>
			{buttons}
		</div>
	)
}

export default CommonModal
