import React, {useState, useEffect} from 'react'
import {connect} from "react-redux"
import {removeToast} from "reducers/System"
import Toast from "./Toast"

import s from './ToastHandler.module.sass'
import {CSSTransition, TransitionGroup} from "react-transition-group"

function ToastHandler(props) {

	// const [messages, setMessages] = useState([])

	useEffect(() => {
		// setMessages([...messages, <Toast />])
		// let key = new Date().getTime()
		// setMessages({...messages, [key]: props.toast})
		if (!props.toasts[0]) return
		setTimeout(() => {
			props.removeToast(props.toasts[0].time)
		}, 5000)
	}, [props.toasts])

	// function addRemover(key) {
	// 		let newMessages = {...messages}
	// 		delete newMessages[key]
	// 		setMessages(newMessages)
	// 	// }, 1000)
	// }

	// let msg = Object.values(messages).map(each => <div>{each}</div>)
	console.log(props.toasts)
	let toasts = props.toasts.map(toast => {
		return (
			<CSSTransition key={toast.time} timeout={1000}>
				<Toast {...toast} />
			</CSSTransition>
		)
	})

	return (
		<div className={s.wrapper}>
			<div className={s.toasts}>
				<TransitionGroup>
					{/*<CSSTransition key={}*/}
					{toasts}
				</TransitionGroup>
			</div>
		</div>
	)

}

const mapState = state => {
	return {
		toasts: state.system.toasts
	}
}

export default connect(mapState, {removeToast})(ToastHandler)
