const permissions = [
	{
		name: 'admin',
		label: {
			zh: '管理员',
			en: 'Admin'
		}
	},
	{
		name: 'content',
		label: {
			zh: '网站内容',
			en: 'Content'
		}
	},
	{
		name: 'jobs',
		label: {
			zh: '职位',
			en: 'Jobs'
		}
	},
	{
		name: 'applications',
		label: {
			zh: '职位申请',
			en: 'Job Applications'
		}
	},
	{
		name: 'news',
		label: {
			zh: '新闻',
			en: 'News'
		}
	},

]

export default permissions
