import React from 'react'
import {connect} from "react-redux"
import {addToast, setModal, clearModal} from "reducers/System"
import CommonModal from "../components/system/CommonModal"

function HomePage(props) {

	let x = 0

	let modalProps = {
		heading: 'This is the heading of the modal',
		body: 'This is the body of the modal. This is the body of the modal. This is the body of the modal. This is the body of the modal. This is the body of the modal. This is the body of the modal. This is the body of the modal. This is the body of the modal',
		actions: {
			Accept: () => alert('test'),
			Cancel: () => props.clearModal()
		}
	}

	return (
		<main>
			<div style={{ fontSize: '20px', fontWeight: 'bold' }}>仪表板</div>
			{/*<button onClick={() => props.addToast('from home page' + (x++))}>Toast Test</button>*/}
			{/*<button onClick={() => props.setModal(<CommonModal {...modalProps} />)}>Modal Test</button>*/}
		</main>
	)

}

export default connect(null, {addToast, setModal, clearModal})(HomePage)
