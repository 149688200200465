export function addToast(msg, type) {
	let toast = {
		time: new Date().getTime(),
		msg: msg,
		type: type
	}
	console.log(toast)
	return {
		type: 'ADD_TOAST',
		toast: toast
	}
}

export function removeToast(key) {
	return {
		type: 'REMOVE_TOAST',
		key: key
	}
}

export function setModal(modal) {
	return {
		type: 'SET_MODAL',
		modal: modal
	}
}

export function clearModal() {
	return {
		type: 'CLEAR_MODAL'
	}
}


const SystemSchema = {
	toasts: [],
	modal: null
}


export default function SystemReducer(state = SystemSchema, action) {

	switch (action.type) {
		case 'ADD_TOAST':
			return {
				...state,
				toasts: [action.toast, ...state.toasts]
			}
		case 'REMOVE_TOAST':
			return {
				...state,
				toasts: state.toasts.filter(obj => obj.time !== action.key)
			}
		case 'SET_MODAL':
			return {
				...state,
				modal: action.modal
			}
		case 'CLEAR_MODAL':
			return {
				...state,
				modal: null
			}
		default:
			return state
	}

}
