import React, {useState, useEffect} from 'react'
import NavBar from "includes/NavBar"
import API from "library/API"
import CategoryPanel from "components/jobs/CategoryPanel"

function JobCategoriesPage() {

	const [catData, setCatData] = useState([])

	useEffect(() => {
		let api = new API()
		api.getJobCategories()
			.then(res => {
				setCatData(res.categories)
			})
	}, [])

	function removeCat(_id) {
		setCatData(catData.filter(obj => obj._id !== _id))
	}

	let categories = catData.map(cat => <CategoryPanel {...cat} removeCat={removeCat} />)

	return (
		<main>

			<NavBar title={"职位类型管理"}
				back={"/"}
				actions={{ text: "+ 创建设备类型", link: '/job-category/create' }}
				/>

			{categories}

		</main>
	)

}

export default JobCategoriesPage
