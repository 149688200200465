import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import cx from 'classnames'

import {setUser} from "../reducers/Session"
import {addToast} from "reducers/System"
import {handleFieldChange} from "library/FormHandlers"

import s from './Authenticate.module.sass'
import API from "../library/API"

const errorMessages = {
	'email required': '必须输入邮箱',
	'password invalid': '密码无效',
	'password required': '必须输入密码',
	'already registered': '邮箱已经注册，请登录',
	'invalid': '登录名或登录密码不正确'
}

function AuthenticatePage() { // Login and register

	const dispatch = useDispatch()
	const history = useHistory()
	const [page, setPage] = useState()
	const [loginFields, setLoginFields] = useState({})
	const [regFields, setRegFields] = useState({})


	useEffect(() => {
		setPage(history.location.pathname)
	}, [])

	function changePage() {
		let targetPath = history.location.pathname === '/login' ? '/register' : '/login'
		history.push(targetPath)
		setPage(targetPath)
	}

	function handleLoginChange(e) {
		handleFieldChange(e, loginFields, setLoginFields)
	}

	function handleRegChange(e) {
		handleFieldChange(e, regFields, setRegFields)
	}

	function handleLogin(e) {
		e.preventDefault()
		let api = new API()
		api.login(loginFields)
			.then(res => {
				if (res.result === 'success') {
					dispatch(addToast('登录成功了'))
					dispatch(setUser(res.user))
					history.push('/')
				} else {
					res.errors.forEach(error => {
						// console.log(error)
						dispatch(addToast(errorMessages[error], 'error'))
					})
				}
			})
	}

	function handleRegister(e) {
		e.preventDefault()
		let api = new API()
		api.register(regFields)
			.then(res => {
				if (res.result === 'success') {
					dispatch(addToast('注册成功了'))
					dispatch(setUser(res.user))
					history.push('/')
				} else {
					res.errors.forEach(error => {
						console.log(error)
						dispatch(addToast(errorMessages[error], 'error'))
					})
				}
			})
	}

	return (
		<main>

			<div className={s.panel}>

				<div className={s.tabs}>
					<span className={cx({ [s.active]: page === '/login' })} onClick={changePage}>
						登录
					</span>
					<span className={cx({ [s.active]: page === '/register' })} onClick={changePage}>
						注册
					</span>
				</div>

				<form onSubmit={handleLogin} className={cx({ [s.hidden]: history.location.pathname === '/register' })}>

					<input type={"email"} name={"email"} placeholder={"电子邮件"} value={loginFields.email} onChange={handleLoginChange} />
					<input type={"password"} name={"pass"} placeholder={"密码"} value={loginFields.pass} onChange={handleLoginChange} />

					<input type={"submit"} value={"登录"} />

				</form>

				<form onSubmit={handleRegister} className={cx({ [s.hidden]: history.location.pathname === '/login' })}>

					<input type={"email"} name={"email"} placeholder={"电子邮件"} value={regFields.email} onChange={handleRegChange} />
					<input type={"password"} name={"pass"} placeholder={"密码"} value={regFields.pass} onChange={handleRegChange} />

					<input type={"text"} name={"last"} placeholder={"姓"} value={regFields.last} onChange={handleRegChange} />
					<input type={"text"} name={"first"} placeholder={"名"} value={regFields.first} onChange={handleRegChange} />

					<input type={"submit"} value={"注册"} />

				</form>

			</div>

		</main>
	)

}

export default AuthenticatePage
