export function setUser(user) {

	return {
		type: 'SET_USER',
		email: user.email,
		first: user.first,
		last: user.last,
		permissions: user.permissions
	}

}


export function setLanguage(ln) {

	return {
		type: 'SET_LANGUAGE',
		ln: ln
	}

}

const SessionSchema = {
	email: '',
	first: '',
	last: '',
	permissions: [],
	ln: 'zh'
}

export default function SessionReducer(state = SessionSchema, action) {

	switch (action.type) {
		case 'SET_USER':
			return {
				...state,
				email: action.email,
				first: action.first,
				last: action.last,
				permissions: action.permissions
			}
		case 'SET_LANGUAGE':
			return {
				...state,
				ln: action.ln
			}
		default:
			return state
	}

}
