import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

import {createStore} from "redux"
import {Provider} from "react-redux"
import SessionReducer from "./reducers/Session"
import SystemReducer from "./reducers/System"
import ContentReducer from './reducers/Content'
import {combineReducers} from "redux"

import './styles/app.sass'


let RootReducer = combineReducers({session: SessionReducer, system: SystemReducer, content: ContentReducer})
const store = createStore(RootReducer)


ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
