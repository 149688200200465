import {apiHost} from "global"

class ImageUploadAdapter {
    constructor(loader) {
        this.loader = loader
    }

    async upload() {
        return this.loader.file.then(file =>
            new Promise( (resolve, reject) => {
                console.log('attempting to upload image')
                const data = new FormData()
                data.append('image', file)

                let xhr = new XMLHttpRequest()

                xhr.onreadystatechange = () => {
                    if (xhr.readyState !== 4) {
                        return;
                    }

                    if (xhr.status === 200) {
                        let res = JSON.parse(xhr.response)
                        console.log(res)
                        resolve({
                            default: apiHost + res.url
                        })
                    } else {
                        console.warn('request_error');
                    }
                };

                xhr.open('POST', apiHost + '/ckeditor/uploadImage')
                xhr.send(data)
            })
        )
    }

    abort() {
        console.log('aborting')
        return Promise.reject()
    }
}

function CKAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) =>
    new ImageUploadAdapter(loader)
}

export default CKAdapterPlugin
