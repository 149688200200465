import React, {useEffect, useState} from 'react'
import NavBar from "includes/NavBar"
import API from "library/API"
import PagePanel from "components/pages/Panel"

function PagesList() {

	const [pageData, setPageData] = useState([])

	useEffect(() => {
		let api = new API()
		api.getPages()
			.then(res => {
				setPageData(res.pages)
			})
	}, [])

	let pages = pageData.map(page => <PagePanel slug={page.slug} _id={page._id} />)

	return (
		<main>

			<NavBar title={"编辑页面"}
				back={"/"}
			/>

			<div className={"help"}>新页面需要手动添加设计</div>

			<section>
				{pages}
			</section>

		</main>
	)

}

export default PagesList
