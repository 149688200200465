function handleFieldChange(e, fields, setFields) {
	let {name, value} = e.target
	let nameArr = name.split('.')

	setFields({
		...fields,
		[nameArr[0]]: nameArr.length === 1 ? value : { ...fields[nameArr[0]], [nameArr[1]]: value }
	})
}

export {handleFieldChange}
