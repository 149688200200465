import React, {useEffect} from 'react'
import {Route, Switch, useHistory} from "react-router-dom"
import {useDispatch} from "react-redux"

import API from "./library/API"
import {setUser} from "./reducers/Session"

import AuthenticatePage from "./pages/Authenticate"
import HomePage from "./pages/Home"
import PagesList from "./pages/pages/List"
import SavePage from "./pages/pages/Save"
import NewsPage from "./pages/news/List"
import SaveNewsPage from "./pages/news/Save"
import ProductsPage from "./pages/products/List"
import SaveProductPage from "./pages/products/Save"
import ProductCategoriesPage from "./pages/productCategory/List"
import SaveProductCategoryPage from "./pages/productCategory/Save"
import EquipmentPage from "./pages/equipment/List"
import SaveEquipmentPage from "./pages/equipment/Save"
import EquipmentCategoriesPage from "./pages/equipmentCategory/List"
import SaveEquipmentCategoryPage from "./pages/equipmentCategory/Save"
import JobsPage from "./pages/jobs/List"
import SaveJobPage from "./pages/jobs/Save"
import JobCategoriesPage from "./pages/jobCategory/List"
import SaveJobCategoryPage from "./pages/jobCategory/Save"
import UsersPage from "./pages/users/List"

function Pages() {

	const history = useHistory()
	const dispatch = useDispatch()

	useEffect(() => {
		let api = new API()
		api.resumeSession()
			.then(res => {
				console.log(res)
				if (res.session.user) {
					dispatch(setUser(res.session.user))
				} else {
					history.push('/login')
				}
			})
	}, [])

	return (
		<Switch>

			<Route exact path={"/login"}>
				<AuthenticatePage />
			</Route>

			<Route exact path={"/register"}>
				<AuthenticatePage />
			</Route>

			<Route exact path={"/"}>
				<HomePage />
			</Route>


			{/*-- Pages --*/}

			<Route exact path={"/pages"}>
				<PagesList />
			</Route>

			<Route exact path={"/page/create"}>
				<SavePage />
			</Route>

			<Route exact path={"/page/edit/:_id"}>
				<SavePage />
			</Route>


			{/*-- News --*/}

			<Route exact path={"/news"}>
				<NewsPage />
			</Route>

			<Route exact path={"/news/create"}>
				<SaveNewsPage />
			</Route>

			<Route exact path={"/news/edit/:_id"}>
				<SaveNewsPage />
			</Route>


			{/*-- Products --*/}

			<Route exact path={"/products"}>
				<ProductsPage />
			</Route>

			<Route exact path={["/product/create", "/product/edit/:_id"]}>
				<SaveProductPage />
			</Route>


			<Route exact path={"/product-categories"}>
				<ProductCategoriesPage />
			</Route>

			<Route exact path={["/product-category/create", "/product-category/edit/:_id"]}>
				<SaveProductCategoryPage />
			</Route>


			{/*-- Equipment --*/}

			<Route exact path={"/equipment"}>
				<EquipmentPage />
			</Route>

			<Route exact path={["/equipment/create", "/equipment/edit/:_id"]}>
				<SaveEquipmentPage />
			</Route>

			<Route exact path={"/equipment-categories"}>
				<EquipmentCategoriesPage />
			</Route>

			<Route exact path={["/equipment-category/create", "/equipment-category/edit/:_id"]}>
				<SaveEquipmentCategoryPage />
			</Route>


			{/*-- Jobs --*/}

			<Route exact path={"/jobs"}>
				<JobsPage />
			</Route>

			<Route exact path={["/jobs/create", "/jobs/edit/:_id"]}>
				<SaveJobPage />
			</Route>

			<Route exact path={"/job-categories"}>
				<JobCategoriesPage />
			</Route>

			<Route exact path={["/job-category/create", "/job-category/edit/:_id"]}>
				<SaveJobCategoryPage />
			</Route>


			{/*-- Users --*/}

			<Route exact path={"/users"}>
				<UsersPage />
			</Route>


		</Switch>
	)

}

export default Pages
