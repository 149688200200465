import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {setModal, clearModal} from "reducers/System"

import API from "library/API"
import Product from "library/Product"

import CommonModal from "components/system/CommonModal"

import 'styles/global.sass'

function Panel(props) {

	let product = new Product(props)
	console.log(product)

	let modalProps = {
		heading: '确认删除',
		body: '你确认想删除此产品吗?',
		actions: {
			'确认': deleteProduct,
			'取消': props.clearModal
		}
	}

	function confirmDelete() {
		props.setModal(<CommonModal {...modalProps} />)
	}

	function deleteProduct() {
		let api = new API()
		api.deleteProduct(props._id)
			.then(res => {
				// console.log(res)
				props.removeProduct(props._id)
				props.clearModal()
			})
	}

	return (
		<div className={"panel"}>

			<div className={"cover"}>
				<img src={product.getImage('thumb')} />
			</div>

			<div className={"column"}>
				<div>类型：{product.category}</div>
				<div>{product.name.en}</div>
				<div>{product.name.zh}</div>
			</div>

			<div className={"column"}>
				<div>{product.description.en}</div>
				<div>{product.description.zh}</div>
			</div>

			<div className={"actions"}>
				<Link to={"#"}>查看</Link>
				<Link to={"/product/edit/" + product._id}>编辑</Link>
				<button onClick={confirmDelete}>删除</button>
			</div>

		</div>
	)

}

export default connect(null, {setModal, clearModal})(Panel)
