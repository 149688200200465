import React from 'react'
import {Link} from 'react-router-dom'

import 'styles/global.sass'

function PagePanel(props) {

	return (
		<div className={"panel"}>

			<div className={"column"}>
				<div>{props.slug}</div>
				<div>{props.title ? props.title.zh : null}</div>
				<div>{props.title ? props.title.en : null}</div>
			</div>

			<div className={"column"}>
				<div>{props.body ? props.body.zh : null}</div>
			</div>

			<div className={"column"}>
				<div>{props.body ? props.body.en : null}</div>
			</div>

			<div className={"actions"}>
				<Link className={"inactive"} to={"#"}>查看</Link>
				<Link to={"/page/edit/" + props._id}>编辑</Link>
			</div>

		</div>
	)

}

export default PagePanel
