import React, {useState, useEffect} from 'react'
import cx from 'classnames'
import {useRouteMatch, useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {addToast} from "reducers/System"
import API from "../../library/API"
import NavBar from "../../includes/NavBar"

import s from './Save.module.sass'
import fs from 'styles/forms.module.sass'
import {handleFieldChange} from "../../library/FormHandlers"

function SaveEquipmentPage(props) {

	const history = useHistory()
	const [catData, setCatData] = useState([])
	const match = useRouteMatch()
	let _id = match.params._id
	let ln = props.ln

	const [fields, setFields] = useState({
		name: { en: '', zh: '' },
		description: { en: '', zh: '' }
	})

	useEffect(() => {
		let api = new API()
		api.getEquipmentCategories()
			.then(res => {
				setCatData(res.categories)
			})

		if (_id) {
			api.getEquipmentInfo(_id)
				.then(res => {
					setFields(res.equipment)
				})
		}

	}, [])

	function changeCategory(_id) {
		setFields({ ...fields, category: _id })
	}

	function handleChange(e) {
		handleFieldChange(e, fields, setFields)
	}

	function handleFile(e) {
		setFields({ ...fields, image: e.target.files })
	}

	async function handleSubmit(e) {
		e.preventDefault()
		let api = new API()
		let data = {...fields}
		if (_id) data._id = _id
		let res = await api.saveEquipment(data)
		console.log(res)
		props.addToast('设备保存成功')
		if (!_id) history.push('/equipment')
	}

	let categories = catData.map(cat => {
		return (
			<div className={cx(fs.option, { [fs.selected]: cat._id === fields.category })}
				 onClick={() => changeCategory(cat._id)}>
				{cat.name.zh}
			</div>
		)
	})

	return (
		<main>

			<NavBar title={"编辑设备"}
				back={"/equipment"}
			/>

			<section>

				<form className={fs.standard} onSubmit={handleSubmit}>

					<div className={fs.clickSelector}>
						{categories}
					</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>名称 (中文)</label>
							<input type={"text"} name={"name.zh"} value={fields.name.zh} onChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>名称 (English)</label>
							<input type={"text"} name={"name.en"} value={fields.name.en} onChange={handleChange} />
						</div>
					</div>

					<div className={fs.set}>
						<div className={fs.field}>
							<label>描述 (中文)</label>
							<textarea name={"description.zh"} value={fields.description.zh} onChange={handleChange} />
						</div>
						<div className={fs.field}>
							<label>描述 (English)</label>
							<textarea name={"description.en"} value={fields.description.en} onChange={handleChange} />
						</div>
					</div>


					<input type={"file"} name={"image"} multiple={true} onChange={handleFile} />
					<input type={"submit"} value={"保存设备"} />

				</form>

			</section>

		</main>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}


export default connect(mapState, {addToast})(SaveEquipmentPage)
