const schema = {
	products: [],
	equipment: [],
	jobCategories: []
}

const ContentReducer = (state = schema, action) => {
	switch (action.type) {
		default:
			return state
		case 'SET_EQUIPMENT':
			return {
				...state,
				equipment: action.equipment
			}
		case 'SET_PRODUCTS':
			return {
				...state,
				products: action.products
			}
		case 'SET_JOB_CATEGORIES':
			return {
				...state,
				jobCategories: action.categories
			}
	}
}

export default ContentReducer
