import React, {useState, useEffect} from 'react'
import NavBar from "includes/NavBar"
import API from "library/API"
import Panel from "components/posts/Panel"

export default function NewsPage() {

	const [postData, setPostData] = useState([])

	useEffect(() => {
		let api = new API()
		api.getPosts()
			.then(res => {
				setPostData(res.posts)
			})
	}, [])

	function removePost(_id) {
		setPostData(postData.filter(obj => obj._id !== _id))
	}

	let posts = postData.map(post => <Panel {...post} removePost={removePost}  />)

	return (
		<main>

			<NavBar title={"新闻管理"}
				back={"/"}
				actions={{ text: "+ 创建新闻", link: '/news/create' }}
				/>

			{posts}

		</main>
	)

}
