import React from 'react'
import {apiHost} from "../../global"

import s from './Application.module.sass'
import API from "../../library/API";

export default function Application(props) {

	function deleteApplication() {
		let api = new API()
		api.deleteApplication(props._id)
			.then(res => {
				console.log(res)
				props.removeApp(props._id)
			})
	}

	return (
		<div className={s.app}>
			<div className={s.info}>
				<div className={s.id}>ID: {props._id}</div>
				<div>申请人姓名: {props.name}</div>
			</div>
			<div className={s.cover}>求职信: {props.cover || '无'}</div>
			<div className={s.actions}>
				<a className={props.file ? '' : s.disabled}
				   	download={props.name + ' - 简历'}
				   	target={"_blank"}
			   		href={apiHost + `/media/applications/${props.file}`}>
					下载
				</a>
				<a onClick={deleteApplication}>删除</a>
			</div>
		</div>
	)

}
