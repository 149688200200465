import React, {useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux"
import NavBar from "includes/NavBar"
import API from "../../library/API"

import Panel from 'components/equipment/Panel'

function EquipmentPage() {

	const dispatch = useDispatch()
	const equipmentData = useSelector(state => state.content.equipment)

	useEffect(() => {
		let api = new API()
		api.getEquipment()
			.then(res => {
				dispatch({ type: 'SET_EQUIPMENT', equipment: res.equipment })
			})
	}, [])

	function removeEquipment(_id) {
		dispatch({ type: 'SET_EQUIPMENT', equipment: equipmentData.filter(obj => obj._id !== _id) })
	}

	let equipment = equipmentData.map(equipment => {
		return (
			<Panel key={equipment._id} {...equipment}
				   removeEquipment={removeEquipment}
			/>
		)
	})

	return (
		<main>

			<NavBar title={"设备管理"}
				back={"/"}
				actions={{ text: "+ 创建设备", link: '/equipment/create' }}
			/>

			{equipment}

		</main>
	)

}

export default EquipmentPage
