import React, {useState, useEffect} from 'react'
import {useDispatch} from "react-redux"
import NavBar from "../../includes/NavBar"
import API from "../../library/API"
import JobPanel from "../../components/jobs/Panel"

export default function JobsPage() {

	const dispatch = useDispatch()
	const [jobData, setJobData] = useState([])

	useEffect(() => {
		let api = new API()
		api.getJobCategories()
			.then(res => {
				console.log('job categories', res)
				dispatch({ type: 'SET_JOB_CATEGORIES', categories: res.categories })
			})
		api.getJobs()
			.then(res => {
				setJobData(res.jobs)
			})
	}, [])

	function removeJob(_id) {
		setJobData(jobData.filter(obj => obj._id !== _id))
	}

	let jobs = jobData.map(job => <JobPanel key={job._id} {...job} removeJob={removeJob} />)

	return (
		<main>

			<NavBar title={"职位管理"}
				back={"/"}
				actions={{ text: "+ 创建职位", link: '/jobs/create' }}
				/>

			{jobs}

		</main>
	)

}
